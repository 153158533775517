@import url('bootstrap.min.css');
@import url('svgloader.css');
:root {
  --main-bg-color: #000000;
  --primary-button-color: #1DAAC2;
  --primary-text-color: #FFFFFF;
  --box-color: #2D3034;
  --ligher-button-color: #26C1DB;
  --secondary-button-color: #3C4045;
}

body {
  margin: 0;
  font-family: 'Circular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.App {
  text-align: center;
  background-color: var(--main-bg-color);
  color: var(--primary-text-color);
  min-height: 100vh;
  max-width:1800px;
  margin: auto;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

  .page-main-container {
    padding-right: 0;
    padding-left: 0;
    max-width: 100%;
  }

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.supercard {
  background-color: #2D3034;
  border: 1px solid #3C4045;
  max-width: 100%;
  padding: 1em;
  margin: 4em .5em .5em .5em;
  color: white;
  border-radius: 5px;
  font-weight: 600;
  min-height: 262px;

}

.login-submit-button {
  width: 6em;
}

.home-container {
  padding: 10em 0 0 0;
}

.home-container-lg {
    padding: 3em 0 0 0;
    max-width: 1800px;
}

.start-card-top {
  text-align: center;
}

.start-card-bottom {
  width: 100%;
  margin: 2em 0 0 0;
  text-align: center;
}

.max350 {
  max-width: 521px;
}
.wider-form {
  min-width: 100%;
}

.fade-in {
  animation: fadeIn ease .7s;
  -webkit-animation: fadeIn ease .7s;
  -moz-animation: fadeIn ease .7s;
  -o-animation: fadeIn ease .7s;
  -ms-animation: fadeIn ease .7s;
  }
  @keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }

  @-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }

  @-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }

  @-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }

  @-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }

  .pwList {
    list-style: none;
    margin-left: -30px;
  }

  .red-x:before{

    content: "\2717";
    color: red;
    font-weight: 700;
    margin-right: 5px;
  }

  .grey-check:before{

    content: "\2717";
    color: lightgrey;
    font-weight: 700;
    margin-right: 5px;
  }

  .green-check:before {
    font-weight: 700;
    content: "\2713";
    color: green;
    margin-right: 5px;
  }

  .white-link{
    transition: all .7s;
    color: white
  }

  .white-link:hover {
    color: red;
    text-decoration: underline;
    transition: all .7s;
  }

  .half-sidemargin {
    margin: '.5em 0 .5em'
  }

  .signup-header {
    border-radius: 5px;
    background-color: rgb(38, 193, 219, .2);
    width: 85%;
    padding: .75em;
    color: #26C1DB;
    font-size: 1em;
    font-weight: 500;
    margin: 2em auto 0 auto;
  }

.dark-input {
    border: 1px solid #595959 !important;
    border-radius: 5px !important;
    background-color: rgba(255,255,255,0.07) !important;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.06) !important;
    color: rgb(255,255,255,.5) !important;
  }

  .dark-input option {
    background-color: rgb(78, 78, 78) !important;
    color: rgb(255,255,255,.5) !important;
  }

  .agree-text {
    font-size: .75em;
    opacity: 0.5;
    color: #FFFFFF;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 2em;
  }

  input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
  background-color: rgba(255,255,255,0.07) !important;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.06) !important;
    -webkit-box-shadow:0 1px 3px 0 rgba(0,0,0,0.06) !important;
}

.margin5 {
  margin: 0 5px 2px 0px;
}

.side-margin-1em {
  margin: 0 1em;
}

.justify-left {
  display: flex;
  justify-content: left;
  text-align: left;
}

.justify-center {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.scrollable-metrics {
  display: flex;
  border: 1px solid black;
  font-size: small;
  overflow-y: scroll;
  overflow-x: none;
  height: 500px;

}

.sidebar {
  width: 257px;
  background-color: #14151A;
  color: rgba(255,255,255,.5);
  position: fixed;
  top: 0;
  bottom: 0;
  min-height: 100vh !important;
  z-index: 100;
  padding: 20px 0 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
  text-align: left;
  font-weight: 700;
}
.sidebar a {
  color: rgba(255,255,255,.5);
  fill: rgba(255,255,255,.5);
  stroke: rgba(255,255,255,.5);
  border-left: 3px solid transparent;
}


.sidebar a:hover {
  color: rgba(255,255,255,.75);
  fill: rgba(255,255,255,.75);
  stroke: rgba(255,255,255,.75);
}
.sidebar .selected {
  border-left: 3px solid #FFFFFF;
  background-color: rgba(255,255,255,.05);
  color: #FFFFFF;
  fill: rgba(255,255,255,.95);
  stroke: rgba(255,255,255,.95);
}

.sidebar-logo {
  padding-left: 20px;
}

.navbar-logo {
  padding:12px 15px 0 6px;
}

.sidebar-subhead {
  margin-top: 40px;
  padding-left: 17px;
  color: rgba(255,255,255,.3);
  font-size: .75rem;
}

.navbar-subhead {
  padding-left: 17px;
  color: rgba(255,255,255,.3);
  font-size: .75rem;
}

.sidebar-svg {
  display: inline-flex;
  margin-right: 10px;
}

.navbar-svg {
  display: inline-flex;
  margin: 2px 4px;
  color: rgba(175, 143, 143, 0.5);
  fill: rgba(255,255,255,.5);
  stroke: rgba(255,255,255,.5);
}
.navbar-wrapper{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.navbar-fullmenu {
  padding-top: 0;
}

.divider-holder {
  display: flex;
  width: 100%;
  height: 8px;
  justify-content: center;
  margin: 6px 0 0 0;
}

.divider {
  width: 85%;
  height: 1px;
  background-color: rgba(255,255,255,.3);

}

.resource-table {
  width: 100%;
  white-space: normal !important;
}

#sidebar-wrapper{
    min-height: 100vh !important;
    min-width: 225px;
    /* width: 100vw; */
    /* margin-left: -1rem; */
    -webkit-transition: margin .25s ease-out;
    -moz-transition: margin .25s ease-out;
    -o-transition: margin .25s ease-out;
    transition: margin .25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
    padding: 0.875rem 1.25rem;
    font-size: 1.2rem;
}

#page-content-wrapper {
    width: 100%;
    max-width: calc(100% - 200px);
    padding-left: 30px;
    color: white;
}

.nav-current-box {
  height: 32px;
  width: 32px;
  border-radius: 1.8px;
  margin: 15px 10px 15px 32px;
  background-color: #26C1DB;
  box-shadow: 0 1px 8px 0 rgba(48,205,175,0.3);
  fill: rgba(255,255,255,.95);
  stroke: rgba(255,255,255,.95);
}

.flex-inline {
  display: inline-flex;
}

.nav-current-name {
  font-weight: 700;
  margin:  17px 0;
}

.navbar-profile-background {
  display: grid;
  place-content: end center;
  height: 40px;
  width: 40px;
  margin-top: -10px;
  border-radius: 20px;
  background-color: #26C1DB;
  overflow: hidden;

}

.nav-profile-txt {
  margin: 0px 0 0 8px;
  font-weight: 700;
  color: white;
}

.dropdown-menu {
  background-color: rgb(20, 21, 26);
  color:#ffffff;
}

.dropdown-item {
  color:#ffffff;
}

.dropdown-item:hover {
  background-color: rgba(255,255,255,.15);
  color:#ffffff;
}

.dropdown-toggle::after{
  content: none;
}

.sb-navbar {
  background-color: #14151A;
  width: 100%;
  margin-right: 0;
  margin-left: 0;
  /* padding-right: 20px; */
}

.your-profile-bg {
  box-sizing: border-box;
  margin: 1em 1em .5em 1em;
  max-width: 99%;
  border: 1px solid #3C4045;
  border-radius: 5px;
  background-color: #2D3034;
  box-shadow: 0 1px 7px 0 rgba(0,0,0,0.05);
    /* overflow-x: scroll; */
}

.your-profile-bg tr{
  border-bottom: 2px solid #3B3E43;
}

.your-profile-bg th{
  padding: 4px;
}
.your-profile-bg td{
  height: 16px;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 26px;
  padding: 5px;
  text-align: left;
  color: #6A7D8E;
}

.even-child-right td:nth-child(even) {
  color: white;
  text-align: right;
}

.after-2nd-white td:nth-child(n+3) {
  color: white;
}

.width-100 {
  min-width: 100%
}

.maxw100 {
  max-width: 100%
}

.justify-right {
  display: inline-block;
  justify-items: right;
  text-align: right;
}

.pad-p5 {
  padding: .5em
}

.fontsize-p85 {
  font-size: .85rem;
}

.fontsize-p100 {
  font-size: 1rem;
}

.width-25em {
  max-width: 25em;
}

.margin-auto {
  margin: auto;
}

.ruler-div {
  box-sizing: border-box;
  height: 1px;
  width: 100%;
  border: 1px solid #3B3E43;
}

.green-alert {
  background-color: rgb(52, 76, 84) ;
  color: #26C1DB;
  border: none;
}

.bold {
  font-weight: 700;
}

.half-size {
  -webkit-transform:scale(.5);
  -webkit-transform-origin:top center;
  -moz-transform:scale(.5);
  -moz-transform-origin:top center;
  -ms-transform:scale(.5);
  -ms-transform-origin:top center;
  -o-transform:scale(.5);
  -o-transform-origin:top center;
  transform:scale(.5);
  transform-origin:top center;
}


.tenant-title-box {
  position: relative;
  box-sizing: border-box;
  height: 43px;
  width: 124px;
  border: 2px solid #3C4045;
  border-radius: 5px;
  background-color: #2F3238;
  color: white;
  letter-spacing: 0;
  font-weight: 600;
  line-height: 18px;
  margin: 12px auto;
  z-index: 5;

}

.width100 {
  width: 100%;
}

.dashcard-bg {
  box-sizing: border-box;
  height: 140px;
  min-width: 330px;
  border: 1px solid #3C4045;
  border-radius: 3px;
  background-color: #2D3034;
  box-shadow: 0 1px 7px 0 rgba(0,0,0,0.05);
  margin: 15px;
  flex: 1 1 350px;
}

.dashcard-main {
  color: #FFFFFF;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 30px;
  margin: 25px auto 3px auto;
}

.dashcard-title {
  color: #FFFFFF;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 28px;
  text-align: left;
  padding: 0 3px 0 3px;

}

.dashcard-subtext{
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
  color: #5AC891;
}

.dc-red {
  color: #D53232
}

.flex-spread {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.width-25p{
  width: 25%;
}
.break-word {
    word-wrap: break-word;
    word-break: break-all;
    white-space: normal;
}


.main-container {
  padding: 0;
}

.fw500 {
  font-weight: 500;
}

.dark-modal .modal-content {
  background-color: var(--main-bg-color);
  color: whitesmoke;
  font-weight: 500;
  font-size: .9em;
  border-color:rgb(52, 76, 84, .5);
  font-family: 'Circular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.dark-modal-header {
  font-weight: 600;
}

.dark-modal-header > button {
  color: grey
}

.dark-modal-header > button:hover {
  color: white
}

.resource-accordion{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  max-width: 100%;
  border: 1px solid #3C4045;
  border-radius: 5px;
  background-color: #2D3034;
  box-shadow: 0 1px 7px 0 rgba(0,0,0,0.05);
  color: whitesmoke;
  text-align: left;
  font-weight: 500;
}

.zero-mp {
  margin: 0;
  padding: 0;
}

.toggle-up {
  transition: transform .5s;
}

.toggle-down {
  transform-origin: center center;
  transform: rotate(180deg);
  transition: transform .5s;
}

.pl15  {
  padding-left: 15px;
}

.pl30  {
  padding-left: 30px;
}


.fullscreen-modal .modal-content {
    position: fixed;
    width: calc(100% - 20px);
    height: calc(100% - 80px);
    top: 70px;
    left: 10px;
    right: 10px;
    bottom: 10px;
 }

 .search-modal-body {
   overflow-y: auto;
 }

 .borderless-input {
   border: none;
   background-color: black;
   color: white;
   font-size: 1.3em;
   width: calc(100% - 50px);
 }

 .nav-icons {
   margin: .5em
 }
  .nav-icons:hover {
   stroke: white
 }

  .borderless-input:focus{
   outline: none;
 }


.log-tabs > a{
  border-color: whitesmoke !important;
  background-color: black;
  color:#1DAAC2 !important;
}

.log-tabs > a.active{
  color: white !important;
  background-color:rgb(30,30,30) !important;
  border-bottom-color: transparent !important;
}


.log-tab {
  color: white;
  border-left: solid 1px whitesmoke;
  border-right: solid 1px whitesmoke;
  border-bottom: solid 1px whitesmoke;
  background-color:rgb(30,30,30);
  margin: 0 -10px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.log-tabs {
  border-bottom: 1px solid whitesmoke !important;
  margin-bottom: 0px;
  flex-direction: row;
  margin-left: -10px;
  margin-right: -10px;
}

.log-tabs > .log-tab {
  padding-left: 3px;
  padding-right: 3px;
}

@media(min-width: 550px) {
  .navbar-wrapper{
    flex-direction: row;
  }
}

@media(min-width: 768px) {
  .navbar-fullmenu {
    display: none;
  }
    #page-content-wrapper {
    width: calc(100% - 10px);
  }
}

@media(max-width: 767px) {
    .profile-dropdown {
    display: none;
  }
    #sidebar-wrapper {
    display: none;
  }
  #page-content-wrapper {
    padding: 0;
    max-width: 100%;
  }
  .page-main-container {
    padding: 0;
  }
}