.ic-Spin-cycle--faces, .ic-Spin-cycle--police-car, .ic-Spin-cycle--circles, .ic-Spin-cycle--classic-magenta-purple, .ic-Spin-cycle--classic {
  box-sizing: border-box;
  display: inline-block;
  vertical-align: end;
  overflow: visible;
}
.ic-Spin-cycle--faces > svg, .ic-Spin-cycle--police-car > svg, .ic-Spin-cycle--circles > svg, .ic-Spin-cycle--classic-magenta-purple > svg, .ic-Spin-cycle--classic > svg {
  display: block;
  overflow: visible;
}
.ic-Spin-cycle--faces > svg > *, .ic-Spin-cycle--police-car > svg > *, .ic-Spin-cycle--circles > svg > *, .ic-Spin-cycle--classic-magenta-purple > svg > *, .ic-Spin-cycle--classic > svg > * {
  transform: translate3d(0, 0, 0);
  transform-origin: center;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

.ic-Spin-cycle--classic {
  margin: 12px 24px;
  width: 72px;
  height: 72px;
}
@-webkit-keyframes spin-cycle {
  from {
    fill: #53c7db;
    transform: scale(1);
  }
  to {
    fill: rgba(50, 127, 161, 0.65);
    transform: scale(1);
  }
}
@keyframes spin-cycle {
  from {
    fill: #53c7db;
    transform: scale(1);
  }
  to {
    fill: rgba(50, 127, 161, 0.65);
    transform: scale(1);
  }
}
.ic-Spin-cycle--classic > svg {
  width: 72px;
  height: 72px;
}
.ic-Spin-cycle--classic > svg > * {
  fill: rgba(50, 127, 161, 0.65);
  -webkit-animation-name: spin-cycle;
          animation-name: spin-cycle;
  -webkit-animation-duration: .45s;
          animation-duration: .45s;
}
.ic-Spin-cycle--classic > svg > *:nth-of-type(1) {
  -webkit-animation-delay: 0.15s;
          animation-delay: 0.15s;
}
.ic-Spin-cycle--classic > svg > *:nth-of-type(2) {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}
.ic-Spin-cycle--classic > svg > *:nth-of-type(3) {
  -webkit-animation-delay: 0.45s;
          animation-delay: 0.45s;
}
.ic-Spin-cycle--classic > svg > *:nth-of-type(4) {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}
.ic-Spin-cycle--classic > svg > *:nth-of-type(5) {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
.ic-Spin-cycle--classic > svg > *:nth-of-type(6) {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}
.ic-Spin-cycle--classic > svg > *:nth-of-type(7) {
  -webkit-animation-delay: 0.7s;
          animation-delay: 0.7s;
}
.ic-Spin-cycle--classic > svg > *:nth-of-type(8) {
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
}

.ic-Spin-cycle--classic-magenta-purple {
  margin: 12px 24px;
  width: 72px;
  height: 72px;
}
@-webkit-keyframes yellow {
  from {
    fill: #ef0878;
    transform: scale(1);
  }
  to {
    fill: #5f5ffe;
    transform: scale(1);
  }
}
@keyframes yellow {
  from {
    fill: #ef0878;
    transform: scale(1);
  }
  to {
    fill: #5f5ffe;
    transform: scale(1);
  }
}
.ic-Spin-cycle--classic-magenta-purple > svg {
  width: 72px;
  height: 72px;
}
.ic-Spin-cycle--classic-magenta-purple > svg > * {
  fill: #5f5ffe;
  -webkit-animation-name: yellow;
          animation-name: yellow;
  -webkit-animation-duration: 1.6s;
          animation-duration: 1.6s;
}
.ic-Spin-cycle--classic-magenta-purple > svg > *:nth-of-type(1) {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
}
.ic-Spin-cycle--classic-magenta-purple > svg > *:nth-of-type(2) {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}
.ic-Spin-cycle--classic-magenta-purple > svg > *:nth-of-type(3) {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}
.ic-Spin-cycle--classic-magenta-purple > svg > *:nth-of-type(4) {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}
.ic-Spin-cycle--classic-magenta-purple > svg > *:nth-of-type(5) {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
.ic-Spin-cycle--classic-magenta-purple > svg > *:nth-of-type(6) {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}
.ic-Spin-cycle--classic-magenta-purple > svg > *:nth-of-type(7) {
  -webkit-animation-delay: 0.7s;
          animation-delay: 0.7s;
}
.ic-Spin-cycle--classic-magenta-purple > svg > *:nth-of-type(8) {
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
}

.ic-Spin-cycle--circles {
  margin: 12px 24px;
  width: 100px;
  height: 72px;
}
@-webkit-keyframes circles {
  from {
    fill: rgba(95, 95, 254, 0.8);
    transform: scale(1.5);
  }
  to {
    fill: rgba(95, 95, 254, 0.5);
    transform: scale(1);
  }
}
@keyframes circles {
  from {
    fill: rgba(95, 95, 254, 0.8);
    transform: scale(1.5);
  }
  to {
    fill: rgba(95, 95, 254, 0.5);
    transform: scale(1);
  }
}
.ic-Spin-cycle--circles > svg {
  width: 100px;
  height: 72px;
}
.ic-Spin-cycle--circles > svg > * {
  fill: rgba(95, 95, 254, 0.5);
  -webkit-animation-name: circles;
          animation-name: circles;
  -webkit-animation-duration: 1.6s;
          animation-duration: 1.6s;
}
.ic-Spin-cycle--circles > svg > *:nth-of-type(1) {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
}
.ic-Spin-cycle--circles > svg > *:nth-of-type(2) {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}
.ic-Spin-cycle--circles > svg > *:nth-of-type(3) {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}
.ic-Spin-cycle--circles > svg > *:nth-of-type(4) {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}
.ic-Spin-cycle--circles > svg > *:nth-of-type(5) {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
.ic-Spin-cycle--circles > svg > *:nth-of-type(6) {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}
.ic-Spin-cycle--circles > svg > *:nth-of-type(7) {
  -webkit-animation-delay: 0.7s;
          animation-delay: 0.7s;
}
.ic-Spin-cycle--circles > svg > *:nth-of-type(8) {
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
}

.ic-Spin-cycle--police-car {
  margin: 12px 24px;
  width: 100px;
  height: 72px;
}
@-webkit-keyframes your-animation {
  from {
    fill: #5f5ffe;
    transform: scale(1.1);
  }
  to {
    fill: rgba(34, 34, 34, 0.8);
    transform: scale(0.9);
  }
}
@keyframes your-animation {
  from {
    fill: #5f5ffe;
    transform: scale(1.1);
  }
  to {
    fill: rgba(34, 34, 34, 0.8);
    transform: scale(0.9);
  }
}
.ic-Spin-cycle--police-car > svg {
  width: 100px;
  height: 72px;
}
.ic-Spin-cycle--police-car > svg > * {
  fill: rgba(34, 34, 34, 0.8);
  -webkit-animation-name: your-animation;
          animation-name: your-animation;
  -webkit-animation-duration: 1.6s;
          animation-duration: 1.6s;
}
.ic-Spin-cycle--police-car > svg > *:nth-of-type(1) {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
}
.ic-Spin-cycle--police-car > svg > *:nth-of-type(2) {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}
.ic-Spin-cycle--police-car > svg > *:nth-of-type(3) {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}
.ic-Spin-cycle--police-car > svg > *:nth-of-type(4) {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}
.ic-Spin-cycle--police-car > svg > *:nth-of-type(5) {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
.ic-Spin-cycle--police-car > svg > *:nth-of-type(6) {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}
.ic-Spin-cycle--police-car > svg > *:nth-of-type(7) {
  -webkit-animation-delay: 0.7s;
          animation-delay: 0.7s;
}
.ic-Spin-cycle--police-car > svg > *:nth-of-type(8) {
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
}

.ic-Spin-cycle--faces {
  margin: 12px 24px;
  width: 100px;
  height: 72px;
}
@-webkit-keyframes faces {
  from {
    fill: rgba(239, 8, 120, 0.8);
    transform: scale(1.25);
  }
  to {
    fill: rgba(95, 95, 254, 0.7);
    transform: scale(1);
  }
}
@keyframes faces {
  from {
    fill: rgba(239, 8, 120, 0.8);
    transform: scale(1.25);
  }
  to {
    fill: rgba(95, 95, 254, 0.7);
    transform: scale(1);
  }
}
.ic-Spin-cycle--faces > svg {
  width: 100px;
  height: 72px;
}
.ic-Spin-cycle--faces > svg > * {
  fill: rgba(95, 95, 254, 0.7);
  -webkit-animation-name: faces;
          animation-name: faces;
  -webkit-animation-duration: 2.4s;
          animation-duration: 2.4s;
}
.ic-Spin-cycle--faces > svg > *:nth-of-type(1) {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
}
.ic-Spin-cycle--faces > svg > *:nth-of-type(2) {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}
.ic-Spin-cycle--faces > svg > *:nth-of-type(3) {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}
.ic-Spin-cycle--faces > svg > *:nth-of-type(4) {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}
.ic-Spin-cycle--faces > svg > *:nth-of-type(5) {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
.ic-Spin-cycle--faces > svg > *:nth-of-type(6) {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}
.ic-Spin-cycle--faces > svg > *:nth-of-type(7) {
  -webkit-animation-delay: 0.7s;
          animation-delay: 0.7s;
}
.ic-Spin-cycle--faces > svg > *:nth-of-type(8) {
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
}
.ic-Spin-cycle--faces > svg > *:nth-of-type(9) {
  -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s;
}
.ic-Spin-cycle--faces > svg > *:nth-of-type(10) {
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}
.ic-Spin-cycle--faces > svg > *:nth-of-type(11) {
  -webkit-animation-delay: 1.1s;
          animation-delay: 1.1s;
}
.ic-Spin-cycle--faces > svg > *:nth-of-type(12) {
  -webkit-animation-delay: 1.2s;
          animation-delay: 1.2s;
}